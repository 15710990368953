import * as React from "react";
import { navigate } from "gatsby-link";
import Layout from "../../components/Layout";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error));
  };

  render() {
    return (
      <Layout>
        <section className="section section--gradient" style={{
            backgroundImage: "url(/img/globe-bg.jpg)",
            backgroundSize: "100%",
            backgroundColor: "#000C29",
            overflow: "visible",
            backgroundRepeat: "no-repeat",
            paddingTop: "2.5em",
            paddingBottom: "5em",
            height: "1200px",
          }}>
          <div className="container">
            <div className="content">
              <div className="columns">
                  <div className="column is-6 is-offset-1">
                    <h1 style={{
                      color: "#30CAFF",
                    }}>Let’s work together on your next wireless project</h1>
                    <p>We provide expertise in industrial wireless product development, large-scale wireless network deployment, and preventive maintenance programs for clients around the world. We respond to inquiries within 24 hours.</p>
                    <h2 style={{
                      color: "#A1DE1D",
                    }}>Contact Us</h2>
                      <form
                        name="contact"
                        method="post"
                        action="/contact/thanks/"
                        data-netlify="true"
                        data-netlify-honeypot="bot-field"
                        onSubmit={this.handleSubmit}
                        class="subtitle"
                      >
                        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                        <input type="hidden" name="form-name" value="contact" />
                        <div hidden>
                          <label>
                            Don’t fill this out:{" "}
                            <input name="bot-field" onChange={this.handleChange} />
                          </label>
                        </div>
                        <div className="field" >
                          <label className="label" htmlFor={"name"} style={{
                          color: "white",
                        }}>
                            Your name
                          </label>
                          <div className="control">
                            <input
                              className="input"
                              type={"text"}
                              name={"name"}
                              onChange={this.handleChange}
                              id={"name"}
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="field">
                          <label className="label" htmlFor={"email"} style={{
                          color: "white",
                        }}>
                            Email
                          </label>
                          <div className="control">
                            <input
                              className="input"
                              type={"email"}
                              name={"email"}
                              onChange={this.handleChange}
                              id={"email"}
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="field">
                          <label className="label" htmlFor={"message"} style={{
                          color: "white",
                        }}>
                            Message
                          </label>
                          <div className="control">
                            <textarea
                              className="textarea"
                              name={"message"}
                              onChange={this.handleChange}
                              id={"message"}
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="field">
                          <button className="btn" type="submit">
                            Send
                          </button>
                        </div>
                      </form>
                  </div>
                  <div className="column is-4" style={{
                    marginLeft: "5em",
                    marginTop: "5em",
                  }}>
                    <img src="/img/favicon-512x512.png" style={{
                      width: "300px",
                    }}></img>
                  </div>
              </div>
              </div>
          </div>
        </section>
      </Layout>
    );
  }
}
